import apiClient from '@/services/ApiClient.js'
import store from '@/store/index'

export default {
  getUsers(limit, offset, type) {
    let url = `/users/?limit=${limit}&offset=${offset}`
    if (type == 'active') {
      url += `&is_active=true`
    } else if (type == 'not_active') {
      url += `&is_active=false`
    } else if (type == 'admin') {
      url += `&is_admin=true`
    }
    return apiClient.get(url)
  },
  deleteUser(userID) {
    return apiClient.delete(`/users/${userID}/`)
  },
  getUser(userID) {
    return apiClient.get(`/users/${userID}/`)
  },
  createUser(userData) {
    return apiClient.post('/users/', userData)
  },
  updateUser(userID, userData) {
    return apiClient.put(`/users/${userID}/`, userData)
  },
  changeUserActive(userID, active) {
    return apiClient.put(`/users/${userID}/active/?active=${active}`)
  },
  changeUserPassword(userID, userData) {
    return apiClient.put(`/users/${userID}/reset_password/`, userData)
  },
}
