<template>
  <v-card>
    <v-breadcrumbs :items="breadcrumbs">
      <template v-slot:divider>
        <v-icon>mdi-forward</v-icon>
      </template>
    </v-breadcrumbs>
    <v-dialog v-model="dialog" persistent max-width="600px">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          class=".float-left ml-20"
          dark
          v-bind="attrs"
          v-on="on"
        >
          <v-icon>mdi-briefcase-plus-outline</v-icon>
          Add user
        </v-btn>
      </template>
      <v-form @submit.prevent="CreateORUpdateUser">
        <v-card>
          <v-card-title>
            <span class="headline">{{ userObj.login || 'User' }}</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="User login*"
                    :error-messages="nameErrors"
                    v-model="userObj.login"
                    required
                    @blur="$v.userObj.login.$touch()"
                    >{{ userObj.login }}</v-text-field
                  >
                </v-col>
              </v-row>
              <v-row v-if="!userToUpdate">
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-if="!userToUpdate"
                    label="password*"
                    :error-messages="password1Errors"
                    v-model="userObj.password1"
                    required
                    type="password"
                    @blur="$v.userObj.password1.$touch()"
                    >{{ userObj.password1 }}</v-text-field
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    v-if="!userToUpdate"
                    label="confirm password*"
                    :error-messages="password2Errors"
                    v-model="userObj.password2"
                    required
                    type="password"
                    @blur="$v.userObj.password2.$touch()"
                    >{{ userObj.password2 }}</v-text-field
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="First name"
                    v-model="userObj.first_name"
                    required
                    @blur="$v.userObj.first_name.$touch()"
                    >{{ userObj.first_name }}</v-text-field
                  >
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" sm="12" md="12">
                  <v-text-field
                    label="Last name"
                    v-model="userObj.last_name"
                    required
                    @blur="$v.userObj.last_name.$touch()"
                    >{{ userObj.last_name }}</v-text-field
                  >
                </v-col>
                <v-row>
                  <v-col sm="6">
                    <v-checkbox
                      v-model="userObj.is_active"
                      :label="`Is active`"
                    ></v-checkbox>
                  </v-col>
                  <v-col sm="6">
                    <v-checkbox
                      v-model="userObj.is_admin"
                      :label="`Is admin`"
                    ></v-checkbox>
                  </v-col>
                </v-row>
              </v-row>
            </v-container>
            <small>*indicates required field</small>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Close
            </v-btn>
            <v-btn
              color="blue darken-1"
              text
              type="submit"
              :disabled="$v.anyError"
            >
              Save
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-card-title>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="items"
      :options.sync="options"
      :server-items-length="total"
      :search="search"
      :footer-props="{
        'items-per-page-options': itemsPerPage
      }"
      class="elevation-1 text-center"
    >
      <template v-slot:item.is_active="{ item }">
        <v-tooltip left v-if="item.is_active">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green lighten-1" v-bind="attrs" v-on="on" dark>
              mdi-check-circle
            </v-icon>
          </template>
          <span>active</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="yellow darken-2" v-bind="attrs" v-on="on" dark>
              mdi-close-circle
            </v-icon>
          </template>
          <span>not active</span>
        </v-tooltip>
      </template>
      <template v-slot:item.is_admin="{ item }">
        <v-tooltip left v-if="item.is_admin">
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="green lighten-1" v-bind="attrs" v-on="on" dark>
              mdi-check-circle
            </v-icon>
          </template>
          <span>admin</span>
        </v-tooltip>
        <v-tooltip left v-else>
          <template v-slot:activator="{ on, attrs }">
            <v-icon color="yellow darken-2" v-bind="attrs" v-on="on" dark>
              mdi-close-circle
            </v-icon>
          </template>
          <span>not active</span>
        </v-tooltip>
      </template>
      <template v-slot:item.action="{ item }">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              class="margin-rigth-5"
              @click="changeUserActive(item)"
            >
              <v-icon v-if="item.is_active" color="yellow darken-4"
                >mdi-account-lock</v-icon
              >
              <v-icon v-else color="green darken-1">mdi-account-check</v-icon>
            </v-btn>
          </template>
          <span v-if="item.is_active">lock account</span>
          <span v-else>unlock account</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="getUser(item._id)"
              class="margin-rigth-5"
              ><v-icon color="teal"> mdi-pencil </v-icon></v-btn
            >
          </template>
          <span>edit</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-bind="attrs"
              v-on="on"
              icon
              @click="deleteItem(item)"
              color="red darken-2"
              class="margin-rigth-5"
              ><v-icon>
                mdi-delete
              </v-icon></v-btn
            >
          </template>
          <span>delete</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
            v-bind="attrs"
            v-on="on"
            icon
            class="margin-rigth-5"
            @click="changeUserPasswordForm(item.login, item._id)"
            ><v-icon color="gray darken-2"> mdi-lock-question </v-icon></v-btn
            >
          </template>
          <span>reset password</span>
        </v-tooltip>
      </template>
    </v-data-table>
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline"
          >Are you sure you want to delete user with login
          <b>'{{ deletedItemName }}'</b>?</v-card-title
        >
        <v-card-actions>
          <v-btn color="blue darken-1" dark @click="closeDelete">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn color="red lighten-1" dark @click="deleteItemConfirm"
            >OK</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="changePasswordDialog" persistent max-width="600px">
      <v-form @submit.prevent="changeUserPassword">
        <v-card>
        <v-card-title>
          <span class="headline">Change password for {{ username }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col sm="6">
                <v-text-field
                  label="password*"
                  :error-messages="changepassword1Errors"
                  v-model="changePassword.password1"
                  type="password"
                  required
                  @blur="$v.changePassword.password1.$touch()"
                  >{{ changePassword.password1 }}</v-text-field
                >
              </v-col>
            </v-row>
            <v-row>
              <v-col sm="6">
                <v-text-field
                  label="confirm password*"
                  :error-messages="changepassword2Errors"
                  v-model="changePassword.password2"
                  type="password"
                  required
                  @blur="$v.changePassword.password2.$touch()"
                  >{{ changePassword.password2 }}</v-text-field
                >
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="changePasswordDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            color="blue darken-1"
            text
            type="submit"
            :disabled="$v.anyError"
          >
            Save
          </v-btn>
        </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </v-card>
</template>

<script>
import { required, url } from 'vuelidate/lib/validators'
import UserService from '@/services/UserService.js'
import { paginationMixin } from '@/mixins/PaginationMixin.js'

export default {
  mixins: [paginationMixin],
  data() {
    return {
      userObj: this.createFreshUserObj(),
      username: '',
      userID: '',
      changePassword: { password1: '', password2: '' },
      userToUpdate: false,
      dialog: false,
      changePasswordDialog: false,
      headers: [
        {
          text: 'Login',
          align: 'center',
          value: 'login'
        },
        {
          text: 'First Name',
          align: 'center',
          value: 'first_name'
        },
        { text: 'Last name', value: 'last_name' },
        { text: 'is_active', value: 'is_active' },
        { text: 'is_admin', value: 'is_admin' },
        { text: 'Actions', value: 'action' }
      ]
    }
  },
  created() {
    this.setBreadcrumbs([
      { text: 'Home', to: { path: '/' } },
      {
        text: ':admin'
      }
    ])
    this.replaceBreadcrumb({
      find: ':admin',
      replace: {
        text: 'Admin',
        to: {
          name: 'Admin'
        },
        disabled: true
      }
    })
  },
  validations() {
    let changePassword = {
      password1: { required },
      password2: { required }
    }
    if (!this.userToUpdate) {
      return {
        userObj: {
          login: { required },
          password1: { required },
          password2: { required },
          first_name: {},
          last_name: {},
          is_active: {},
          is_admin: {}
        },
        changePassword
      }
    } else {
      return {
        userObj: {
          login: { required },
          first_name: {},
          last_name: {},
          is_active: {},
          is_admin: {}
        },
        changePassword
      }
    }
  },
  methods: {
    createFreshUserObj() {
      return {
        login: '',
        password1: '',
        password2: '',
        first_name: '',
        last_name: '',
        is_active: true,
        is_admin: false
      }
    },
    changeUserPasswordForm(userName, userID) {
      this.username = userName
      this.userID = userID
      this.changePasswordDialog = true
    },
    changeUserPassword() {
      UserService.changeUserPassword(this.userID, {password: this.changePassword.password1, confirm_password: this.changePassword.password2})
        .then(() => {
          this.username = ''
          this.userID = ''
          const notification = {
            type: 'success',
            message: `change password for ${this.username}`
          }
          this.$store.dispatch('notification/add', notification, {
            root: true
          })
        })
        .catch(error => {
          const notification = {
            type: 'error',
            message: `${error.response.data.detail}`
          }
          this.$store.dispatch('notification/add', notification, {
            root: true
          })
          this.$store.dispatch('setLoader', false)
        })
    },
    changeUserActive(item) {
      UserService.changeUserActive(item._id, !item.is_active)
        .then(() => {
          console.log(!item.is_active)
          this.getDataFromAPI(0).then(data => {
            // console.log(data.items)
            this.items = data.items
            this.total = data.total
          })
          const notification = {
            type: 'success',
            message: `Active status chagne for ${this.userObj.login}`
          }
          this.$store.dispatch('notification/add', notification, {
            root: true
          })
        })
        .catch(error => {
          const notification = {
            type: 'error',
            message: `${error.response.data.detail}`
          }
          this.$store.dispatch('notification/add', notification, {
            root: true
          })
          this.$store.dispatch('setLoader', false)
        })
    },
    getDataFromAPI(type, offset) {
      // console.log(this.options)
      return new Promise((resolve, reject) => {
        const { sortBy, sortDesc, page, itemsPerPage } = this.options
        let items = []
        type = type !== undefined ? type : this.itemType
        const query = this.$route.query
        let total = 0
        let sort_by = sortBy.length == 1 ? sortBy[0] : '_id'
        let sort = sortDesc.length && sortDesc[0] == 1 ? -1 : 1
        offset = offset !== 0 ? itemsPerPage * (page - 1) : 0
        UserService.getUsers(
          itemsPerPage,
          offset || 0,
          sort_by,
          sort,
          type,
          this.search
        )
          .then(response => {
            // console.log(response.data.results)
            items = response.data.results
            total = response.data.count
            resolve({ items, total })
          })
          .catch(error => {
            console.log(error)
            store.dispatch('setLoader', false)
          })
      })
    },
    deleteItemConfirm() {
      const index = this.items.indexOf(this.DeleteItem)
      UserService.deleteUser(this.DeleteItem._id).then(() => {
        this.items.splice(index, 1)
        this.closeDelete()
        const notification = {
          type: 'success',
          message: `${this.DeleteItem.title} deleted.`
        }
        this.$store.dispatch('notification/add', notification, { root: true })
      })
    },
    CreateORUpdateUser() {
      if (!this.$v.userObj.$invalid) {
        if (!this.userToUpdate) {
          UserService.createUser(this.userObj)
            .then(() => {
              this.userObj = this.createFreshUserObj()
              this.getDataFromAPI(0).then(data => {
                // console.log(data.items)
                this.items = data.items
                this.total = data.total
              })
              const notification = {
                type: 'success',
                message: `User with login ${this.userObj.login} created.`
              }
              this.$store.dispatch('notification/add', notification, {
                root: true
              })
            })
            .catch(error => {
              console.log(error)
              const notification = {
                type: 'error',
                message: `${error.response.data.detail}`
              }
              this.$store.dispatch('notification/add', notification, {
                root: true
              })
              this.$store.dispatch('setLoader', false)
            })
        } else {
          UserService.updateUser(this.userObj._id, this.userObj)
            .then(() => {
              const notification = {
                type: 'success',
                message: `User with login ${this.userObj.login} updated.`
              }
              this.$store.dispatch('notification/add', notification, {
                root: true
              })
              this.userToUpdate = false
              this.getDataFromAPI(0).then(data => {
                // console.log(data.items)
                this.items = data.items
                this.total = data.total
              })

              this.userObj = this.createFreshUserObj()
            })
            .catch(error => {
              const notification = {
                type: 'error',
                message: `${error.response.data.detail}`
              }
              this.$store.dispatch('notification/add', notification, {
                root: true
              })
              this.$store.dispatch('setLoader', false)
            })
        }
        this.dialog = false
      }
    },
    getUser(userID) {
      UserService.getUser(userID)
        .then(response => {
          this.userToUpdate = true
          this.userObj = response.data
          this.dialog = true
        })
        .catch(error => {
          const notification = {
            type: 'error',
            message: `${error.response.data.detail}`
          }
          this.$store.dispatch('notification/add', notification, {
            root: true
          })
          this.$store.dispatch('setLoader', false)
        })
    }
  },
  computed: {
    nameErrors() {
      const errors = []
      if (!this.$v.userObj.login.$dirty) return errors
      !this.$v.userObj.login.required && errors.push('Name is required.')
      return errors
    },
    password1Errors() {
      const errors = []
      if (!this.$v.userObj.password1.$dirty) return errors
      !this.$v.userObj.password1.required &&
        errors.push('Password is required.')
      return errors
    },
    password2Errors() {
      const errors = []
      if (!this.$v.userObj.password1.$dirty) return errors
      !this.$v.userObj.password1.required &&
        errors.push('Password is required.')
      !(this.$v.userObj.password1.$model == this.$v.userObj.password2.$model) &&
        errors.push('Passwords not equal.')
      return errors
    },
    changepassword1Errors() {
      const errors = []
      if (!this.$v.changePassword.password1.$dirty) return errors
      !this.$v.changePassword.password1.required &&
        errors.push('Password is required.')
      return errors
    },
    changepassword2Errors() {
      const errors = []
      if (!this.$v.changePassword.password1.$dirty) return errors
      !this.$v.changePassword.password1.required &&
        errors.push('Password is required.')
      !(
        this.$v.changePassword.password1.$model ==
        this.$v.changePassword.password2.$model
      ) && errors.push('Passwords not equal.')
      return errors
    }
  }
}
</script>

<style scoped></style>
